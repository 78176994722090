<template>
    <div class="MemberMonthSettle" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select v-model="form.deptGroupCode" @change="handleSelectDeptGroup" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in meta.deptGroups"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in meta.types"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="审核状态">
                            <el-select placeholder="请选择" v-model="form.reviewStatus">
                                <el-option
                                    v-for="e in meta.reviewEnums"
                                    :label="e.label"
                                    :value="e.code"
                                    :key="e.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.report.member.monthSettle.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.report.member.monthSettle.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="showReviewDialog"
                size="small"
                v-if="hasPrivilege('menu.report.member.monthSettle.review')"
                >审核
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.member.monthSettle.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog"> 表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="memberSettleTable"
                ref="memberSettleTable"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="50" type="index" fixed="left" />
                <el-table-column label="结算月份" width="80" prop="month" v-if="showColumn('month')" />
                <el-table-column
                    label="机构组名称"
                    width="110"
                    prop="deptGroupName"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column label="机构名称" width="140" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="审核状态"
                    width="80"
                    prop="reviewStatus"
                    key="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="期初日期" width="90" prop="startDate" v-if="showColumn('startDate')" />
                <el-table-column label="期末日期" width="90" prop="endDate" v-if="showColumn('endDate')" />
                <el-table-column
                    label="收取工本费"
                    width="100"
                    prop="allInWorkCost"
                    align="right"
                    :formatter="mTwo"
                    v-if="showColumn('allInWorkCost')"
                />
                <el-table-column
                    label="充值笔数"
                    width="80"
                    prop="allRechargeCount"
                    :formatter="mCount"
                    v-if="showColumn('allRechargeCount')"
                />
                <el-table-column
                    label="充值金额"
                    width="100"
                    prop="allRechargeMoney"
                    align="right"
                    :formatter="mTwo"
                    v-if="showColumn('allRechargeMoney')"
                />
                <el-table-column
                    label="支出工本费"
                    width="100"
                    prop="allOutWorkCost"
                    align="right"
                    :formatter="mTwo"
                    v-if="showColumn('allOutWorkCost')"
                />
                <el-table-column
                    label="退款笔数"
                    width="80"
                    prop="allRefundCount"
                    :formatter="mCount"
                    v-if="showColumn('allRefundCount')"
                />
                <el-table-column
                    label="退款金额"
                    width="100"
                    prop="allRefundMoney"
                    align="right"
                    :formatter="mTwo"
                    v-if="showColumn('allRefundMoney')"
                />
                <el-table-column label="创建时间" width="140" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    prop="operate"
                    key="operate"
                    min-width="145"
                    header-align="center"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            @click="handleDetail(scope.row)"
                            v-if="hasPrivilege('menu.report.member.monthSettle.open')"
                        >
                            查看
                        </el-button>
                        <el-button
                            size="small"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.report.member.monthSettle.delete')"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" :baseUrl="baseUrl" @completed="handleQuery" />
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import EfStartDate from '../../../components/EfStartDate';
import EfEndDate from '../../../components/EfEndDate';
import EfReviewBiz from 'components/EfReviewBiz';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'MemberMonthSettle',
    components: { EfEndDate, EfStartDate, EfReviewBiz, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            baseUrl: '/member/monthSettle',
            emptyZero: true,
            form: {
                deptGroupCode: '',
                deptCode: '',
                startTime: '',
                reviewStatus: '',
                endTime: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/member/monthSettle/page',
                create: '/member/monthSettle/createExtend',
                delete: '/member/monthSettle/delete',
                queryDeptByGroup: '/system/dept/deptTree',
                export: '/member/monthSettle/exportExtend',
            },
            selectedBizArr: [],
            meta: {
                deptGroups: [],
                types: [],
                reviewEnums: Util.reviewStatusEnum(true),
            },
            tableRef: 'memberSettleTable',
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.DeptGroup(_this, (rst) => {
            _this.meta.deptGroups = rst;
            this.handleQuery();
        });
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            if (this.$refs['memberSettleTable'].selection.length != 1) {
                this.$message.error('请选择要一条导出的单据');
                return;
            }
            const code = this.$refs['memberSettleTable'].selection[0].code;
            UrlUtils.Export(this, '会员月结报表', this.url.export, { codes: code });
        },
        handleCreate() {
            const _this = this;
            if (!_this.form.deptCode) {
                this.$message.error('请选择要生成报表的机构');
                return;
            }
            UrlUtils.PostRemote(
                _this,
                _this.url.create,
                {
                    deptCode: _this.form.deptCode,
                    message: '是否创建会员月结单据?页面数据较多,创建时间长,请耐心等待!',
                },
                null,
                () => {
                    this.$message.success('保存成功');
                    this.handleQuery();
                },
                (msg) => {
                    this.$message.error(msg);
                }
            );
        },
        handleSelectDeptGroup(groupCode) {
            const _this = this;
            _this.meta.types = [];
            _this.form.deptCode = '';
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + groupCode, (rst) => {
                _this.meta.types = rst;
            });
        },
        handleDetail(row) {
            Util.nameJump(this, 'menu.report.member.monthSettle.detail', ['报表管理', '会员月结', '月结详情'], {
                form: row,
                code: row.code,
            });
        },
        handleDelete(row) {
            if (row.reviewStatus) {
                this.$alert('非待审核业务不可删除');
                return;
            }
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
        handleReview() {
            if (this.$refs['memberSettleTable'].selection.length != 1) {
                this.$message.error('请选择仅一条单据进行审核');
            } else {
                let _a = 0;
                this.$refs['memberSettleTable'].selection.forEach((value) => {
                    _a += Math.abs(value.reviewStatus);
                });
                if (_a == 0) {
                    const _arr = [];
                    this.$refs['memberSettleTable'].selection.forEach((value) => {
                        _arr.push(value.code);
                    });
                    this.auditCodes = _arr;
                    this.meta.showReview = true;
                } else {
                    this.$message.error('非待审核单据不能审核');
                }
            }
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
        showReviewDialog() {
            this.$refs.review.open(this.selectedBizArr);
        },
        handlePrint() {
            this.printHTML('memberSettleTable', this.$route.meta.title);
        },
        mTwo: Util.mTwo,
        mCount: Util.mCount,
    },
};
</script>

<style scoped>
.InOutDetail .el-form-item {
    margin-bottom: 0;
}
</style>
