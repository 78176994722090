<template>
    <el-form-item label="开始日期">
        <el-date-picker
            placeholder="选择日期"
            v-model="startTime"
            value-format="yyyy-MM-dd"
            :clearable="clearable"
            :editable="false"
            :picker-options="pickerOptions"
            v-bind="$attrs"
            v-on="$listeners"
        />
    </el-form-item>
</template>

<script>
import Util from 'js/Util';

export default {
    name: 'EfStartDate',
    model: {
        prop: 'date',
        event: 'change',
    },
    props: {
        date: {
            type: String,
            default: '',
        },
        clearable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            startTime: this.defaultStartTime(),
            pickerOptions: {
                disabledDate: (time) => {
                    //不能大于当前日期
                    return time.getTime() > Date.now();
                },
            },
        };
    },
    watch: {
        date: {
            handler(n, o) {
                if (Util.isEmpty(n)) {
                    n = Util.formatDateOfLastMonth();
                }
                this.startTime = n;
            },
            immediate: true,
        },
        startTime: {
            handler(n, o) {
                this.$emit('change', n);
            },
            immediate: true,
        },
    },
};
</script>
